import { CandidateJobType, CandidateShift, candidateShift } from '@/lib/api/v1';
import { Profession } from '@/lib/types';

const professionToName: Record<Profession, string> = {
    CNA: 'Certified Nursing Assistant',
    RN: 'Registered Nurse',
    NP: 'Nurse Practitioner',
    LPN: 'Licensed Practical Nurse',
    HHA: 'Home Health Aide',
};

const jobTypeStrings: Record<CandidateJobType, string> = {
    [CandidateJobType.FullTime]: 'Full time',
    [CandidateJobType.PartTime]: 'Part time',
    [CandidateJobType.PerDiem]: 'Per diem',
};

const shiftsToString: Record<CandidateShift, string> = {
    [CandidateShift.Day]: 'Days',
    [CandidateShift.Evening]: 'Evenings',
    [CandidateShift.Night]: 'Nights',
};

const professionWithExp = {
    func: (profession: string, yearsExp?: number | null) =>
        `${profession}${
            yearsExp !== null
                ? ', ' + yearsExp + (yearsExp === 1 ? ' year' : ' years')
                : ''
        }`,
    example: [Profession.CNA, 12],
};

const oneLineShifts = {
    func: (shifts?: CandidateShift[]) =>
        `${shifts?.map((s) => shiftsToString[s]).join(', ')}`,
    example: [candidateShift],
};

const oneLineFacilities = {
    func: (firstFacility: string, jobMatchesCount: number) => {
        if (!firstFacility) {
            return '';
        }
        return `${firstFacility}${jobMatchesCount > 1 ? ` +${jobMatchesCount - 1}` : ''}`;
    },
    example: ['[FIRST_FACILITY_NAME]', 2],
};

export {
    professionToName,
    jobTypeStrings,
    shiftsToString,
    professionWithExp,
    oneLineShifts,
    oneLineFacilities,
};
