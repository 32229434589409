import { LocationIcon, ProfessionIcon, TimeIcon } from '@/components/svgs';
import { ProfessionAvatar } from '@/components/ui';
import { Profession } from '@/lib/types';
import { cn, getFacilityNameAndCount } from '@/lib/utils';
import NewRequestActionsViewProvider from '@/newRequests/NewRequestActionsViewProvider';
import { homepage as homepageStrings } from '@/strings';
import { useState } from 'react';

import { NewRequestsProps, SmallAttributeProps } from './types';

const strings = homepageStrings.newRequests.newRequestBlock;

function SmallAtrribute({ text, icon, isFirst = false }: SmallAttributeProps) {
    return (
        <>
            {!isFirst && <div className={cn('w-1 h-1 rounded-full bg-black')} />}
            <div className={cn('flex items-center flex-nowrap')}>
                {icon}
                <p className={cn('text-11 whitespace-nowrap font-semibold ml-2')}>
                    {text}
                </p>
            </div>
        </>
    );
}

function NewRequestBlock({ process, openCandidateDetails }: NewRequestsProps) {
    const [loading, setLoading] = useState(false);
    const { candidate } = process;
    const { facilitiesCount, facilityName } = getFacilityNameAndCount(process);
    const { name, yearsOfExperience, shifts } = candidate;
    const profession = process.candidate.profession as Profession;
    const transitionCls = cn('transition-all ease-out duration-150');

    return (
        <div
            className={cn(
                'w-full h-[127px] flex items-center bg-white rounded-2xl px-10 mt-4 first-of-type:mt-0 group',
            )}
        >
            <div className={cn('relative flex items-center w-full')}>
                <div className={cn('flex items-center')}>
                    <ProfessionAvatar
                        className={cn('h-12 w-12')}
                        profession={profession}
                    />
                    <div className={cn('ml-4')}>
                        <p
                            className={cn(
                                'text-13 font-bold cursor-pointer hover:text-primary',
                                transitionCls,
                            )}
                            onClick={openCandidateDetails}
                        >
                            {name}
                        </p>
                        <div
                            className={cn(
                                'flex items-center flex-wrap gap-x-3 gap-y-1 mt-1 group-hover:opacity-10 group-hover select-none',
                                transitionCls,
                            )}
                        >
                            <SmallAtrribute
                                isFirst
                                text={strings.professionWithExp.func(
                                    profession,
                                    yearsOfExperience,
                                )}
                                icon={
                                    <ProfessionIcon
                                        className={cn('h-[11px] w-auto fill-black')}
                                    />
                                }
                            />
                            {(shifts?.length ?? 0) > 0 && (
                                <SmallAtrribute
                                    text={strings.shifts.func(shifts ?? undefined)}
                                    icon={
                                        <TimeIcon
                                            className={cn('h-[11px] w-auto fill-black')}
                                        />
                                    }
                                />
                            )}
                            {facilityName && (
                                <SmallAtrribute
                                    text={strings.facilities.func(
                                        facilityName,
                                        facilitiesCount,
                                    )}
                                    icon={
                                        <LocationIcon
                                            className={cn('h-[11px] w-auto fill-black')}
                                        />
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div
                        className={cn(
                            'absolute right-0 opacity-0 group-hover:opacity-100',
                            transitionCls,
                            { 'opacity-100': loading },
                        )}
                    >
                        <NewRequestActionsViewProvider
                            process={process}
                            loading={loading}
                            setLoading={setLoading}
                            approveButtonProps={{ size: 'sm' }}
                            rejectButtonProps={{ className: cn('w-[93px]'), size: 'sm' }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NewRequestBlock;
