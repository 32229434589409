import { AppDispatch, GetRootState } from '@/_helpers/store';
import { AllProcesses } from '@/_selectors';
import apiClient from '@/lib/api';
import {
    InitiatingSide,
    ProcessStatus,
    ProcessSubstatus,
    RecruiterApprovalStatus,
    RecruiterTerminationReason,
} from '@/lib/api/v1';
import pRetry from 'p-retry';

import processesSlice, { saveProcesses } from './processes';
import {
    FetchProcesses,
    FetchResumeLinkProps,
    FetcherName,
    PatchProcessStatus,
    PostTerminateProcess,
    RejectCandidateParams,
    SendProcessNoteParams,
    UpdateJobMatches,
} from './types';

const fetchProcesses = async ({
    processIds,
    status,
    subStatus,
    recrtuierApprovalStatus,
    limit,
}: FetchProcesses) => {
    const response = await apiClient.GET('/api/v1/processes', {
        params: {
            query: {
                limit: limit ?? 50,
                status__in: Array.isArray(status) ? status.join(',') : status,
                substatus__in: Array.isArray(subStatus)
                    ? subStatus.join(',')
                    : subStatus,
                id__in: processIds ? processIds.join(',') : undefined,
                recruiter_approval_status: recrtuierApprovalStatus,
            },
        },
    });
    return response.data!.items;
};

const fetchSingleProcess = async (processId: string) => {
    const response = await apiClient.GET('/api/v1/processes/{process_id}', {
        params: {
            path: { process_id: processId },
        },
    });
    return response.data!;
};

const fetchResumeLink = async ({ candidateId }: FetchResumeLinkProps) => {
    const response = await apiClient.GET('/api/v1/candidates/{candidate_id}/resume', {
        params: { path: { candidate_id: candidateId } },
    });
    return response.data?.url || '';
};

export const fetchResumeForProcess =
    (processId: string) => async (dispatch: AppDispatch, getState: GetRootState) => {
        const processesById = AllProcesses(getState());
        const process = processesById[processId];
        const candidateId = process.candidate.id;
        const resumeLinkUrl = await fetchResumeLink({ candidateId });
        dispatch(
            processesSlice.actions.updateResumeForProcess({
                processId,
                resumeLink: resumeLinkUrl,
            }),
        );
    };

export const resetResumeLink = (processId: string) => (dispatch: AppDispatch) => {
    dispatch(
        processesSlice.actions.updateResumeForProcess({
            processId,
            resumeLink: '',
        }),
    );
};

export const rejectCandidate = ({
    processId,
    reason,
    additionalText,
}: RejectCandidateParams) => {
    const params = {
        reason: reason ? reason : RecruiterTerminationReason.Other,
        additionalText,
    };
    if (!params.additionalText && params.reason === RecruiterTerminationReason.Other) {
        params.additionalText = 'Rejected via recruiter-app with no comment';
    }
    return terminateProcess({
        processId,
        ...params,
    });
};

export const fetchProcessesInProgress = () => (dispatch: AppDispatch) => {
    dispatch(
        saveProcesses({
            fetcherId: FetcherName.InProgress,
            fetchFunction: () =>
                fetchProcesses({
                    status: [
                        ProcessStatus.PhoneInterview,
                        ProcessStatus.InPersonInterview,
                        ProcessStatus.Paperwork,
                        ProcessStatus.Orientation,
                    ],
                    limit: 100,
                }),
        }),
    );
};

export const fetchProcessesPendingFeedback = () => (dispatch: AppDispatch) => {
    dispatch(
        saveProcesses({
            fetcherId: FetcherName.PendingFeedback,
            fetchFunction: () =>
                fetchProcesses({
                    status: [
                        ProcessStatus.PhoneInterview,
                        ProcessStatus.InPersonInterview,
                        ProcessStatus.Orientation,
                    ],
                    subStatus: [ProcessSubstatus.Done],
                    limit: 100,
                }),
        }),
    );
};

export const fetchNewProcesses = () => (dispatch: AppDispatch) =>
    dispatch(
        saveProcesses({
            fetcherId: FetcherName.NewProcesses,
            fetchFunction: () =>
                fetchProcesses({
                    status: ProcessStatus.Matchmaking,
                    recrtuierApprovalStatus: RecruiterApprovalStatus.PendingApproval,
                }),
        }),
    );

export const fetchProcessesByIds =
    (processIds: string[], fetcher?: FetcherName) => (dispatch: AppDispatch) =>
        dispatch(
            saveProcesses({
                fetcherId: fetcher ?? FetcherName.ByProcessId,
                fetchFunction: () =>
                    processIds.length > 0
                        ? fetchProcesses({
                              processIds,
                          })
                        : Promise.resolve([]),
            }),
        );

export const fetchProcess =
    (processId: string, retry = false) =>
    async (dispatch: AppDispatch) => {
        const apiCall = async () => {
            const process = await fetchSingleProcess(processId);
            dispatch(processesSlice.actions.updateProcess(process));
        };
        await pRetry(apiCall, {
            retries: retry ? 2 : 0,
            factor: 2,
            minTimeout: 1000,
            maxRetryTime: 8000,
        });
    };
export const resetProcesses = () => (dispatch: AppDispatch) => {
    dispatch(processesSlice.actions.reset());
};

export const approveCandidate = (processId: string) => async (dispatch: AppDispatch) => {
    const response = await apiClient.PATCH(
        '/api/v1/processes/{process_id}/approvals/recruiter',
        {
            params: {
                path: { process_id: processId },
            },
            body: { approved: true },
        },
    );
    dispatch(processesSlice.actions.updateProcess(response.data!));
};

export const updateProcessStatus =
    ({ processId, status }: PatchProcessStatus) =>
    async (dispatch: AppDispatch) => {
        const response = await apiClient.PATCH('/api/v1/processes/{process_id}/status', {
            params: {
                path: { process_id: processId },
            },
            body: {
                status,
            },
        });
        dispatch(processesSlice.actions.updateProcess(response.data!));
    };

export const updateSelectedJobMatches =
    ({ processId, selectedMatchId }: UpdateJobMatches) =>
    async (dispatch: AppDispatch) => {
        const response = await apiClient.PATCH(
            '/api/v1/processes/{process_id}/matches',
            {
                params: {
                    path: { process_id: processId },
                },
                body: {
                    selectedMatch: selectedMatchId,
                },
            },
        );
        dispatch(processesSlice.actions.updateProcess(response.data!));
    };

export const terminateProcess =
    ({ processId, reason, additionalText }: PostTerminateProcess) =>
    async (dispatch: AppDispatch) => {
        const response = await apiClient.POST(
            '/api/v1/processes/{process_id}/termination',
            {
                params: {
                    path: { process_id: processId },
                },
                body: {
                    terminatedBy: InitiatingSide.Recruiter,
                    reasonType: reason,
                    reason: additionalText,
                },
            },
        );
        dispatch(processesSlice.actions.updateProcess(response.data!));
    };

export const sendProcessNote = async ({ processId, text }: SendProcessNoteParams) => {
    const response = await apiClient.POST('/api/v1/processes/{process_id}/notes', {
        params: {
            path: { process_id: processId },
        },
        body: {
            content: text,
        },
    });
    return response.data!;
};
