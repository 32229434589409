import { ArrowDown } from '@/components/svgs';
import { cn } from '@/lib/utils';
import * as SelectPrimitive from '@radix-ui/react-select';
import * as React from 'react';

import HoverBlockWrapper from '../HoverBlockWrapper';

const SelectBase = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
        popoverStyle?: boolean;
    }
>(({ className, children, popoverStyle = false, ...props }, ref) => (
    <SelectPrimitive.Trigger
        ref={ref}
        className={cn(
            'flex w-full items-center justify-between rounded-xl',
            'transition-colors duration-100 ease-in-out',
            'border border-neutral300  bg-white p-6 xs:p-5 pr-8 text-14 data-[placeholder]:text-neutral300',
            'focus:outline-none  disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1 ',
            {
                'hover:border-primary focus:border-primary data-[state=open]:border-primary':
                    !popoverStyle,
            },
            className,
        )}
        {...props}
    >
        {children}
        {!popoverStyle && (
            <SelectPrimitive.Icon asChild>
                <ArrowDown className={cn('h-4 w-4 ml-2')} />
            </SelectPrimitive.Icon>
        )}
    </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollUpButton
        ref={ref}
        className={cn(
            'flex cursor-default items-center justify-center py-1 bg-white border-b border-neutra100 hover:bg-neutral100',
            className,
        )}
        {...props}
    >
        <ArrowDown className='h-4 w-4 rotate-180' />
    </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.ScrollDownButton
        ref={ref}
        className={cn(
            'flex cursor-default items-center justify-center py-1 bg-white border-t border-neutral100 hover:bg-neutral100',
            className,
        )}
        {...props}
    >
        <ArrowDown className='h-4 w-4' />
    </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName = SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
        arrowsScroll?: boolean;
        popoverStyle?: boolean;
    }
>(
    (
        {
            className,
            children,
            popoverStyle = false,
            arrowsScroll = false,
            position = 'popper',
            ...props
        },
        ref,
    ) => (
        <SelectPrimitive.Portal>
            <SelectPrimitive.Content
                ref={ref}
                className={cn(
                    'relative z-50 rounded-xl w-full max-h-96 min-w-[8rem] overflow-auto text-black shadow-elevation-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 ',
                    'bg-white',
                    position === 'popper' &&
                        'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
                    { 'shadow-elevation-200': popoverStyle },
                    className,
                )}
                position={position}
                {...(popoverStyle ? { sideOffset: 6 } : {})}
                {...props}
            >
                {arrowsScroll && (
                    <HoverBlockWrapper>
                        <SelectScrollUpButton className={cn('py-4')} />
                    </HoverBlockWrapper>
                )}
                <SelectPrimitive.Viewport
                    className={cn(
                        { 'reenforce-scrollbar': !arrowsScroll },
                        position === 'popper' &&
                            'h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]',
                    )}
                >
                    {children}
                </SelectPrimitive.Viewport>
                {arrowsScroll && (
                    <HoverBlockWrapper>
                        <SelectScrollDownButton className={cn('py-4')} />
                    </HoverBlockWrapper>
                )}
            </SelectPrimitive.Content>
        </SelectPrimitive.Portal>
    ),
);
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Label>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Label
        ref={ref}
        className={cn('py-1.5 pl-8 pr-2 text-14 font-semibold', className)}
        {...props}
    />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> & {
        popoverStyle?: boolean;
    }
>(({ className, children, popoverStyle = false, ...props }, ref) => (
    <SelectPrimitive.Item
        ref={ref}
        className={cn(
            'relative flex w-full cursor-pointer select-none items-center py-8 px-6 text-13 font-semibold text-black',
            'outline-none data-[disabled]:pointer-events-none data-[disabled]:opacity-50 ',
            'transition-colors duration-100 ease-in-out',
            'bg-white hover:bg-neutral100 [&:not(:hover)]:data-[state=checked]:bg-neutral100/70',
            {
                'leading-9 py-6 w-[250px] border-b border-neutral100 last-of-type:border-b-0':
                    popoverStyle,
            },
            className,
        )}
        {...props}
    >
        {/* <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <Check className="h-4 w-4" />
      </SelectPrimitive.ItemIndicator>
    </span> */}

        <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
    React.ElementRef<typeof SelectPrimitive.Separator>,
    React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
    <SelectPrimitive.Separator
        ref={ref}
        className={cn('-mx-1 my-1 h-px bg-slate-100 dark:bg-slate-800', className)}
        {...props}
    />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export type SelectBaseProps = SelectPrimitive.SelectProps;
export type SelectContentProps = React.ComponentProps<typeof SelectContent>;
export type SelectTriggerProps = React.ComponentProps<typeof SelectTrigger>;
export type SelectItemProps = React.ComponentProps<typeof SelectItem>;

export {
    SelectBase,
    SelectGroup,
    SelectValue,
    SelectTrigger,
    SelectContent,
    SelectLabel,
    SelectItem,
    SelectSeparator,
    SelectScrollUpButton,
    SelectScrollDownButton,
};
