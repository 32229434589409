import { ProfessionIcon, SalaryIcon, TimeIcon } from '@/components/svgs';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { candidates as candStrings } from '@/strings';

import MainDetailMobile from './MainDetailMobile';

const strings = candStrings.newRequests.candidatesTable.candidateDetails;

function CandidateMainDetailsMobile({ process: p }: { process: Process }) {
    return (
        <div
            className={cn(
                'bg-white rounded-2xl px-10 py-8 overflow-auto hidden-scrollbar',
            )}
        >
            <div className={cn('flex items-start gap-5 w-full')}>
                <div className={cn('relative flex flex-col gap-8 w-1/2 max-w-1/2')}>
                    <MainDetailMobile
                        title={strings.professionTitle}
                        text={strings.profession.func(
                            p.candidate.profession,
                            p.candidate.yearsOfExperience,
                        )}
                        icon={<ProfessionIcon />}
                    />
                    <MainDetailMobile
                        text={strings.jobType.func(p.candidate.jobTypes ?? undefined)}
                        title={strings.jobTypeTitle}
                        icon={<TimeIcon />}
                    />
                    <MainDetailMobile
                        text={strings.shifts.func(p.candidate.shifts ?? undefined)}
                        title={strings.shiftsTitle}
                        icon={<TimeIcon />}
                    />
                </div>
                <div className={cn('relative flex flex-col gap-8 w-1/2 max-w-1/2')}>
                    <MainDetailMobile
                        title={strings.licenseNumberTitle}
                        text={
                            p.candidate.license
                                ? strings.licenseNumber.func(p.candidate.license)
                                : undefined
                        }
                    />
                    <MainDetailMobile
                        text={
                            p.candidate.hourlySalaryFlexible
                                ? strings.flexibleSalary
                                : p.candidate.hourlySalary
                                  ? strings.hourlySalary.func(p.candidate.hourlySalary)
                                  : null
                        }
                        title={strings.hourlySalaryTitle}
                        icon={
                            !p.candidate.hourlySalaryFlexible &&
                            p.candidate.hourlySalary && <SalaryIcon />
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default CandidateMainDetailsMobile;
