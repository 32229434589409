import { Dialog, useDialogContext } from '@/components/ui/dialog';
import { DialogHeaderAnchor } from '@/components/ui/dialog/';
import useSetOpen from '@/hooks/useSetOpen';
import { cn } from '@/lib/utils';
import { useLayoutEffect, useRef } from 'react';

import { SelectBase, SelectTrigger, SelectValue } from './base';
import { MobileSelectContentProps, SelectProps } from './types';

function MobileSelectContent<T extends string>({
    options,
    onChange,
    handleClose,
    rootProps,
    scrollToSelectedOption,
    value,
}: MobileSelectContentProps<T>) {
    const { open, isExpandedToTop } = useDialogContext();
    const selectedItemRef = useRef<HTMLDivElement | null>(null);
    const viewportRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        if (
            open &&
            scrollToSelectedOption &&
            selectedItemRef.current &&
            viewportRef.current
        ) {
            viewportRef.current.scrollTop = selectedItemRef.current.offsetTop;
        }
    }, [open, scrollToSelectedOption]);

    return (
        <div
            {...rootProps}
            ref={viewportRef}
            className={cn(rootProps?.className, {
                'overflow-auto': scrollToSelectedOption || isExpandedToTop,
            })}
        >
            {options.map((opt) => (
                <div
                    key={opt.id}
                    ref={opt.value === value ? selectedItemRef : null}
                    onClick={() => {
                        onChange(opt.value);
                        handleClose();
                    }}
                    className={cn(
                        'py-7 px-6 w-full text-13 font-semibold cursor-pointer select-none hover:bg-accent300',
                    )}
                >
                    {opt.label}
                </div>
            ))}
        </div>
    );
}

function SelectMobile<T extends string>({
    options,
    value,
    onChange,
    placeholder,
    triggerProps,
    mobileContentProps,
    scrollToSelectedOption,
    popoverStyle,
    ...baseProps
}: SelectProps<T>) {
    const { handleOpen, handleClose, open, setOpen } = useSetOpen();
    const selectedOption = options.find((opt) => opt.value === value);
    return (
        <Dialog
            open={open}
            setOpen={setOpen}
            triggerProps={{ asChild: true }}
            customHeader={<DialogHeaderAnchor />}
            trigger={
                <div>
                    <SelectBase value={value} onValueChange={onChange} {...baseProps}>
                        <SelectTrigger
                            popoverStyle={popoverStyle}
                            {...triggerProps}
                            className={cn(
                                'w-[400px] max-w-full',
                                triggerProps?.className,
                            )}
                            onClick={handleOpen}
                        >
                            {selectedOption ? (
                                <div>{selectedOption.label}</div>
                            ) : (
                                <SelectValue placeholder={placeholder} />
                            )}
                        </SelectTrigger>
                    </SelectBase>
                </div>
            }
        >
            <MobileSelectContent
                {...mobileContentProps}
                options={options}
                onChange={onChange}
                handleClose={handleClose}
                value={value}
                scrollToSelectedOption={scrollToSelectedOption}
            />
        </Dialog>
    );
}

export default SelectMobile;
