import { TimeString } from './types';

export class Time {
    hours: number;
    minutes: number;

    constructor(hours: number, minutes = 0) {
        this.hours = hours;
        this.minutes = minutes;
    }

    static createFromValue(value: number) {
        const { hours, minutes } = Time.valueToHsMs(value);
        return new Time(hours, minutes);
    }

    static createFromString(value: string) {
        const [hsString, msString] = value.split(':');
        return new Time(Number(hsString), Number(msString));
    }

    static valueToHsMs(value: number) {
        return {
            hours: Math.floor(value / 60),
            minutes: value % 60,
        };
    }

    withMinutesAdd(minutes: number) {
        return Time.createFromValue(Number(this) + minutes);
    }

    toDate(): Date {
        const date = new Date();
        date.setHours(this.hours, this.minutes, 0, 0);
        return date;
    }

    toString() {
        return `${String(this.hours).padStart(2, '0')}:${String(this.minutes).padStart(2, '0')}` as TimeString;
    }

    valueOf() {
        return this.hours * 60 + this.minutes;
    }

    toJSON() {
        return this.toString();
    }
}
