import { ProfessionIcon, SalaryIcon, TimeIcon } from '@/components/svgs';
import { Process } from '@/lib/types';
import { cn } from '@/lib/utils';
import { candidates as candStrings } from '@/strings';

import MainDetail from './MainDetail';

const strings = candStrings.newRequests.candidatesTable.candidateDetails;

function CandidateMainDetails({ process: p }: { process: Process }) {
    return (
        <div className={cn('bg-white rounded-2xl px-10 py-8 lg:py-6')}>
            <div className={cn('flex flex-wrap gap-3')}>
                <MainDetail
                    title={strings.professionTitle}
                    text={strings.profession.func(
                        p.candidate.profession,
                        p.candidate.yearsOfExperience,
                    )}
                    icon={<ProfessionIcon />}
                />
                <MainDetail
                    title={strings.licenseNumberTitle}
                    text={
                        p.candidate.license
                            ? strings.licenseNumber.func(p.candidate.license)
                            : undefined
                    }
                />
                <MainDetail
                    text={strings.jobType.func(p.candidate.jobTypes ?? undefined)}
                    title={strings.jobTypeTitle}
                    icon={<TimeIcon />}
                />
                <MainDetail
                    text={
                        p.candidate.hourlySalaryFlexible
                            ? strings.flexibleSalary
                            : p.candidate.hourlySalary
                              ? strings.hourlySalary.func(p.candidate.hourlySalary)
                              : null
                    }
                    title={strings.hourlySalaryTitle}
                    icon={
                        !p.candidate.hourlySalaryFlexible &&
                        p.candidate.hourlySalary && <SalaryIcon />
                    }
                />
                <MainDetail
                    text={strings.shifts.func(p.candidate.shifts ?? undefined)}
                    title={strings.shiftsTitle}
                    icon={<TimeIcon />}
                    noDivider
                />
            </div>
        </div>
    );
}

export default CandidateMainDetails;
