import { cn } from '@/lib/utils';
import React, { HTMLAttributes, ReactElement, useEffect, useRef, useState } from 'react';

type HoverBlockWrapperProps = HTMLAttributes<HTMLDivElement> & {
    children: ReactElement;
    blockDuration?: number;
};

const HoverBlockWrapper: React.FC<HoverBlockWrapperProps> = ({
    children,
    blockDuration = 600,
    ...props
}) => {
    const [blocked, setBlocked] = useState(true);
    const childRef = useRef<HTMLElement | null>(null);
    const shouldDispatchEvent = useRef(false);

    const handlePointerDown = () => {
        if (blocked) {
            shouldDispatchEvent.current = true;
        }
    };
    const handlePointerMove = () => {
        if (blocked) {
            shouldDispatchEvent.current = true;
        }
    };

    const handlePointerLeave = () => {
        shouldDispatchEvent.current = false;
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setBlocked(false);
        }, blockDuration);

        return () => clearTimeout(timer);
    }, [blockDuration]);

    useEffect(() => {
        if (!blocked && shouldDispatchEvent.current && childRef.current) {
            const event = new PointerEvent('pointermove', {
                bubbles: true,
                cancelable: true,
            });
            childRef.current.dispatchEvent(event); // Dispatch the event to simulate hover
        }
    }, [blocked]);

    const renderChild = () =>
        React.cloneElement(children, {
            ...children.props,
            ref: childRef,
        });

    return (
        <div
            {...props}
            className={cn('', props.className)}
            onPointerMove={handlePointerMove}
            onPointerDown={handlePointerDown}
            onPointerLeave={handlePointerLeave}
        >
            <div
                className={cn({
                    'pointer-events-none': blocked,
                })}
            >
                {renderChild()}
            </div>
        </div>
    );
};

export default HoverBlockWrapper;
