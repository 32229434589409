import {
    CandidateJobType,
    CandidateShift,
    DayOfWeek,
    candidateJobType,
    candidateShift,
} from '@/lib/api/v1';
import { DayAvError, Profession } from '@/lib/types';
import {
    availableDaysOfWeekToString,
    dayOfWeekToDayNameLong,
    genericToasts,
    jobTypeStrings,
    oneLineFacilities,
    oneLineShifts,
    professionToName,
    professionWithExp,
    shiftsToString,
} from '@/strings/common';

const avErrorToText: Record<DayAvError, string> = {
    [DayAvError.MaxMinIssue]: 'End time must be later than the start time.',
    [DayAvError.Overlapping]: 'These times overlap with another time you selected.',
};

const flexibleSalary = 'Flexible';

export default {
    tabLabel: 'New candidates',
    recruiterAvailability: {
        labelPart1: 'Regular weekly availability:',
        labelPart1Mobile: 'Your availability:',
        labelPart2NoValue: 'Required',
        labelPart2Value: {
            func: availableDaysOfWeekToString,
            example: [[0, 2, 3, 4, 6]],
        },
        buttonCancel: 'Cancel',
        buttonSetAvailability: 'Set availability',
        dialogTitle: 'Set your regular weekly availability',
        dialogSubtitle:
            'Input your general weekly availability. You can change this anytime.',
        updateToast: {
            ...genericToasts.failure,
        },
        emptyDayLabel: 'Unavailable',
        dayLabel: {
            func: (day: DayOfWeek) => dayOfWeekToDayNameLong(day),
            example: [0],
        },
        errors: {
            ...avErrorToText,
        },
    },
    missingRecruiterAvailabilityDialog: {
        title: 'Your weekly availability is required',
        description:
            'Once your weekly availability is set, all of your interviews will be scheduled accordingly.',
        openAvailabilityButton: 'Set your regular weekly availability',
    },
    candidatesTable: {
        showAllFilter: {
            func: (count: number) => `All (${count})`,
            example: [11],
        },
        professionFilter: {
            func: (prof: Profession, count: number) => `${prof} (${count})`,
            example: [Profession.CNA, 8],
        },
        headerNewRequests: {
            func: (count: number) =>
                count === 0
                    ? 'No new candidates'
                    : count === 1
                      ? '1 new candidate'
                      : `${count} new candidates`,
            example: [12],
        },
        headerRole: 'Experience',
        cellRole: professionWithExp,
        headerShifts: 'Shift preference',
        cellShifts: oneLineShifts,

        headerSalary: 'Desired hourly rate',
        cellSalary: {
            func: (hourlySalary: number) => `$${hourlySalary}/h`,
            example: [21],
        },
        flexibleSalary,
        headerFacilities: 'Nearest facilities',
        cellFacilities: oneLineFacilities,
        candidateDetails: {
            noData: 'N/A',
            professionTitle: 'Experience',
            profession: professionWithExp,
            licenseNumberTitle: 'License number',
            licenseNumber: {
                func: (licenseNumber: string) => `#${licenseNumber}`,
                example: ['#CNA63412'],
            },
            jobTypeTitle: 'Role type',
            jobType: {
                func: (jobTypes?: CandidateJobType[]) =>
                    `${jobTypes?.map((jt) => jobTypeStrings[jt]).join(' / ')}`,
                example: [candidateJobType],
            },
            hourlySalaryTitle: 'Desired hourly rate',
            hourlySalary: {
                func: (hourlySalary: number) => `${hourlySalary}/h`,
                example: [21],
            },
            flexibleSalary,
            shiftsTitle: 'Shift preference',
            shifts: {
                func: (shifts?: CandidateShift[]) =>
                    `${shifts?.map((s) => shiftsToString[s]).join(' / ')}`,
                example: [candidateShift],
            },
            jobMatchesTitle: 'Facility',
            facilityDistance: {
                func: (durationInSeconds: number) =>
                    `${Math.round(durationInSeconds / 60)} minutes`,
                example: [60 * 12.7],
            },
            additionalAttributesTitle: 'Additional information',
            smallAdditionalAttributes: {
                hasCar: {
                    yes: 'Owns a car',
                    no: 'No car',
                },
                hasDrivingLicense: {
                    yes: 'Has drving license',
                    no: 'No drving license',
                },
            },
            hasDrivingLicenseLabel: "Driver's license",
            simpleAttributionAnswerYes: 'Yes',
            simpleAttributionAnswerNo: 'No',
            ownsCarLabel: 'Owns a car',
            covidVaccinatedLabel: 'Covid-19',
            vaccinatedAnswerYes: 'Vaccinated',
            vaccinatedAnswerNo: 'Not Vaccinated',
            updateFacility: {
                button: 'Update',
                ...genericToasts.failure,
            },
            resumeCardTitle: 'Resume',
            resumeLoading: 'Loading...',
            resumeFailedToLoadd: 'The PDF file failed to load.',
            resumeFilename: {
                func: (candidateName: string) => `${candidateName} resume.pdf`,
                example: ['[CANDIDATE_FULL_NAME]'],
            },
            resumeMenu: {
                download: 'Download',
                view: 'View',
            },
        },
        emptyTable: 'No new candidates',
    },
    newCandidateActions: {
        approveCandidate: {
            successTitle: 'Candidate approved',
            successSubtitle: {
                func: (name: string) =>
                    `A screening interview will be scheduled with ${name}. \nYou'll be notified once this is confirmed.`,
                example: ['[CANDIDATE_FULL_NAME]'],
            },
            ...genericToasts.failure,
        },
        buttonReject: 'Reject',
        buttonApprove: 'Request Interview',
    },
    professionToName,
};
