import { useIsMobileScreen } from '@/hooks/useMediaQueries';
import { cn } from '@/lib/utils';
import { useEffect, useRef, useState } from 'react';

import {
    SelectBase,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from './base';
import SelectMobile from './selectMobile';
import { SelectProps } from './types';

function SelectDesktop<T extends string>({
    options,
    value,
    onChange,
    customValueLabel,
    placeholder,
    triggerProps,
    scrollToSelectedOption,
    contentProps,
    itemProps,
    popoverStyle,
    ...baseProps
}: SelectProps<T>) {
    const { className: triggerCls, ...restTriggerProps } = triggerProps ?? {};
    const showCustomSelectValue = !!customValueLabel && !!value;
    const [open, setOpen] = useState(false);
    const selectedItemRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        if (open && scrollToSelectedOption && selectedItemRef.current) {
            selectedItemRef.current.scrollIntoView();
        }
    }, [open, scrollToSelectedOption]);

    return (
        <SelectBase
            open={open}
            onOpenChange={setOpen}
            value={value}
            onValueChange={onChange}
            {...baseProps}
        >
            <SelectTrigger
                popoverStyle={popoverStyle}
                className={cn('w-[400px]', triggerCls)}
                {...restTriggerProps}
            >
                {showCustomSelectValue && customValueLabel(value)}
                {!showCustomSelectValue && <SelectValue placeholder={placeholder} />}
            </SelectTrigger>
            <SelectContent popoverStyle={popoverStyle} {...contentProps}>
                <SelectGroup>
                    {options.map((opt) => (
                        <SelectItem
                            popoverStyle={popoverStyle}
                            {...itemProps}
                            key={opt.id}
                            value={opt.value}
                            ref={opt.value === value ? selectedItemRef : null}
                        >
                            {opt.label}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </SelectBase>
    );
}

function Select<T extends string>(props: SelectProps<T>) {
    const isMobileScreen = useIsMobileScreen();
    return isMobileScreen ? <SelectMobile {...props} /> : <SelectDesktop {...props} />;
}

export default Select;
